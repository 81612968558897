
import React, { useState, useEffect } from 'react';
import api from '../util/api';
import { Table, Tag, Button, Space } from 'antd';
import Layout from '../components/Layout';
import {
  IconButton,
  Modal,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert
} from '@mui/material';

import {
  CloseCircleOutlined,
  KeyOutlined,
  FormOutlined,
  RedoOutlined,
  SearchOutlined,
  PlusOutlined,
  MailOutlined
} from '@ant-design/icons';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Zones from "../templates/election_map/model/zone_ss_isee.json";
import TumbonAddr from '../util/TumbonAddr.json'
import {
  convertProvinceTextToId,
} from '../mapbox/mapboxfn'
import Swal from 'sweetalert2';
import {
  validateRequire,
  validateRegex,
  validateEmail,
  validateMinCharacter
} from '../util/validate'

const SUMNUK = [
  {
    "name": "สำนักธรรมาภิบาล และบริหารทั่วไป",
    "value": "01"
  },
  {
    "name": "สำนักบริหารกลยุทธ์ งบประมาณ และการเงิน",
    "value": "02"
  },
  {
    "name": "สำนักพัฒนาหลักประกันโอกาสทางการศึกษา",
    "value": "03"
  },
  {
    "name": "สำนักพัฒนาการเรียนรู้เชิงพื้นที่",
    "value": "04"
  },
  {
    "name": "สำนักพัฒนาคุณภาพครูและสถานศึกษา",
    "value": "05"
  },
  {
    "name": "สถาบันวิจัยเพื่อความเสมอภาคทางการศึกษา",
    "value": "06"
  },
  {
    "name": "สำนักพัฒนานวัตกรรมเพื่อสร้างโอกาสการเรียนรู้",
    "value": "07"
  },
  {
    "name": "ฝ่ายตรวจสอบภายใน",
    "value": "08"
  },
  {
    "name": "สำนักเทคโนโลยีสารสนเทศ",
    "value": "09"
  },
  {
    "name": "ฝ่ายทรัพยากรบุคคล",
    "value": "10"
  },
  {
    "name": "สำนักสื่อสารสาธารณะและระดมความร่วมมือ",
    "value": "11"
  }
]

const MAP_ROLE_TEXT = {
  eef: 'กสศ.',
  partner: 'หน่วยงานภาคี',
  representative: 'สส.',
  admin: 'ผู้ดูแลระบบ',
  partner_province: 'ภาคีจังหวัด'
}

const DEFAULT_USER = {
  user: null,
  name: null,
  lastname: null,
  position: null,
  phone_number: null,
  email: null,
  facebook_line: null,
  department: null,
  provinceId: null,
  zoneId: null,
  num_employee: null,
  objective: null,
  roles: null,

  pass: null,
  confirmpass: null,
}

const PageAdmin = (props) => {
  const [users, setUsers] = useState([])
  const [table_users, setTableUsers] = useState([])
  const [validate_field, setValidateField] = useState({
    user: [],
    name: [],
    lastname: [],
    email: [],
    phone_number: [],
    position: [],
    facebook_line: [],
    department: [],
    provinceId: [],
    zoneId: [],
    num_employee: [],
    objective: [],
    pass: [],
    confirmpass: [],
    roles: []
  })

  const [user_data, setUserData] = React.useState({ ...DEFAULT_USER })

  const [list_zones, setListZone] = useState([])
  const [search_filter, setSearchFilter] = useState({ name: '', roles: '' })
  const [reload, setReload] = useState(false)
  const [openModalRegister, setOpenModalRegister] = useState(false)
  const [openModalChangePass, setOpenModalChangePass] = useState(false)
  const [openModalEditProfile, setOpenModalEditProfile] = useState(false)
  const [openModalDeactivate, setOpenModalDeactivate] = useState(false)
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false);
  const [showConfirmPassword2, setShowConfirmPassword2] = useState(false);
  const [alert_text, setAlertText] = useState({ type: '', text: '' })

  useEffect(async () => {
    let users = await api.getUserForManage()
    setTableUsers(users.userdata)
    setUsers(users.userdata)
    let list = [...new Set(TumbonAddr.map(item => item.provincename))]
      .sort()
      .map(item => ({ province_id: convertProvinceTextToId(item), province_name: item, zone: [] }))
      .map(item => {
        if (Zones.filter(res => res.provinceId.toString() === item.province_id).length > 0) {
          item.zone = Zones.filter(res => res.provinceId.toString() === item.province_id).map(res => res.no).sort()
        }
        return item
      })
    setListZone(list)
  }, [])

  useEffect(async () => {
    if (!reload) return;
    let users = await api.getUserForManage()
    setTableUsers(users.userdata)
    setUsers(users.userdata)
    setReload(false)
  }, [reload])

  const handleCloseModal = () => {
    setValidateField({
      user: [],
      name: [],
      lastname: [],
      email: [],
      phone_number: [],
      position: [],
      facebook_line: [],
      department: [],
      provinceId: [],
      zoneId: [],
      num_employee: [],
      objective: [],
      pass: [],
      confirmpass: [],
      roles: [],
    })
    setAlertText({ type: '', text: '' })
  }

  const onValidateRegister = (data) => {
    validate_field.name = []
    validate_field.name.push(
      validateRequire(data.name)
    )

    validate_field.lastname = []
    validate_field.lastname.push(
      validateRequire(data.lastname)
    )

    validate_field.email = []
    validate_field.email.push(
      validateRequire(data.email),
      validateEmail(data.email)
    )

    validate_field.roles = []
    validate_field.roles.push(
      validateRequire(data.roles)
    )

    if (!validateRequire(data.roles)) {
      if (data.roles.includes("eef") || data.roles.includes("partner") || data.roles.includes("partner_province")) {
        validate_field.department = []
        validate_field.department.push(
          validateRequire(data.department)
        )
      }
      if (data.roles.includes("representative")) {
        validate_field.provinceId = []
        validate_field.provinceId.push(
          validateRequire(data.provinceId)
        )
        validate_field.zoneId = []
        validate_field.zoneId.push(
          validateRequire(data.zoneId)
        )
      }
    }

    setValidateField({ ...validate_field })

    return validate_field

  }

  const onValidateEditprofile = (data) => {
    let reg_text_number = /^[0-9]+$/;

    validate_field.name = []
    validate_field.name.push(
      validateRequire(data.name)
    )

    validate_field.lastname = []
    validate_field.lastname.push(
      validateRequire(data.lastname)
    )

    validate_field.roles = []
    validate_field.roles.push(
      validateRequire(data.roles)
    )

    if (!validateRequire(data.roles)) {
      if (data.roles.includes("eef") || data.roles.includes("partner")) {
        validate_field.department = []
        validate_field.department.push(
          validateRequire(data.department)
        )
      }
      if (data.roles.includes("representative")) {
        validate_field.provinceId = []
        validate_field.provinceId.push(
          validateRequire(data.provinceId)
        )
        validate_field.zoneId = []
        validate_field.zoneId.push(
          validateRequire(data.zoneId)
        )
      }
    }

    if (data.num_employee) {
      validate_field.num_employee = []
      validate_field.num_employee.push(
        validateRegex(data.num_employee, reg_text_number, 'ตัวเลขเท่านั้น')
      )
    } else {
      validate_field.num_employee = []
    }

    validate_field.roles = []
    validate_field.roles.push(
      validateRequire(data.roles)
    )

    setValidateField({ ...validate_field })
    return validate_field
  }

  const onValidateChangpass = (data) => {
    let reg_text_en = /^[a-zA-Z0-9-_]+$/;

    if (data.pass || data.confirmpass) {
      validate_field.pass = []
      validate_field.pass.push(
        validateRequire(data.pass),
        validateMinCharacter(data.pass, 6),
        validateRegex(data.pass, reg_text_en, 'a - Z, 0 - 9')
      )

      validate_field.confirmpass = []
      validate_field.confirmpass.push(
        validateRequire(data.confirmpass),
        // validatePassword(data.pass, data.confirmpass)
      )
    } else {
      validate_field.pass = []
      validate_field.pass.push(
        validateRequire(data.pass),
      )

      validate_field.confirmpass = []
      validate_field.confirmpass.push(
        validateRequire(data.confirmpass)
      )
    }

    setValidateField({ ...validate_field })
    return validate_field
  }

  const register = () => {
    setUserData({
      user: '',
      pass: '',
      confirmpass: '',
      name: '',
      lastname: '',
      email: '',
      phone_number: '',
      department: '',
      provinceId: 0,
      zoneId: 0,
    })
    setOpenModalRegister(true)
  }

  const changePass = (data) => {
    setUserData({
      ...user_data,
      user: data.user,
      email: data.profile.email,
      pass: null,
      confirmpass: null,
    })
    setOpenModalChangePass(true)
  }

  const editProfile = (data) => {
    let profile_data = data.profile
    setUserData({
      ...user_data,
      user: data.user,
      name: profile_data.name ? profile_data.name : null,
      lastname: profile_data.lastname ? profile_data.lastname : null,
      position: profile_data.position ? profile_data.position : null,
      phone_number: profile_data.phone_number ? profile_data.phone_number : null,
      email: profile_data.email ? profile_data.email : null,
      facebook_line: profile_data.facebook_line ? profile_data.facebook_line : null,
      department: profile_data.department ? profile_data.department : null,
      provinceId: profile_data.provinceId ? profile_data.provinceId : null,
      zoneId: profile_data.zoneId ? profile_data.zoneId : null,
      num_employee: profile_data.num_employee ? profile_data.num_employee : null,
      objective: profile_data.objective ? profile_data.objective : null,
      roles: data.roles.length > 0 ? data.roles[0] : null,
    })
    setOpenModalEditProfile(true)
  }

  const deactivateUser = (data, deactivate) => {
    setUserData({
      ...user_data,
      user: data.user,
      deactivate: deactivate
    })
    setOpenModalDeactivate(true)
  }


  const sendEmailAgain = (data) => {
    let profile_data = data.profile
    setUserData({
      ...user_data,
      department: profile_data.department ? profile_data.department : null,
      email: profile_data.email ? profile_data.email : null,
      name: profile_data.name ? profile_data.name : null,
      lastname: profile_data.lastname ? profile_data.lastname : null,
      provinceId: profile_data.provinceId ? profile_data.provinceId : null,
      zoneId: profile_data.zoneId ? profile_data.zoneId : null,
      roles: data.roles.length > 0 ? data.roles[0] : null
    })
    setOpenModalSendEmail(true)
  }


  const onSaveChangePass = async () => {
    try {
      await api.resetpass({ "email": user_data.email })
      setOpenModalChangePass(false)
      handleCloseModal()
      Swal.fire('', 'ส่งอีเมลสำเร็จ', 'success');
    } catch (err) {
      Swal.fire('', err + '', 'error');
    }
  }

  const onSaveProfileChange = async () => {
    let check_validate = false
    let editporfile_validate = onValidateEditprofile(user_data)
    for (let key in editporfile_validate) {
      check_validate = editporfile_validate[key].filter(item => item !== null).length > 0
      if (check_validate) break;
    }
    if (!check_validate) {
      try {
        let update_data = {
          "manage": true,
          "user": user_data.user,
          "profile": {
            "name": user_data.name,
            "lastname": user_data.lastname,
            "position": user_data.position,
            "facebook_line": user_data.facebook_line,
            "department": user_data.department,
            "provinceId": user_data.provinceId,
            "zoneId": user_data.zoneId,
            "num_employee": user_data.num_employee,
            "objective": user_data.objective,
          },
          // "roles": user_data.roles,
        }
        const res = await api.editprofile(update_data)
        setOpenModalEditProfile(false)
        handleCloseModal()
        Swal.fire('', 'บันทึกสำเร็จ', 'success');
        setReload(true)
      } catch (err) {
        let text_alert = ''
        if (Array.isArray(err)) {
          err.forEach((ele, idx) => {
            idx === err.length - 1 ? text_alert += ele : text_alert += (ele + ', ')
          })
        } else {
          text_alert = err
        }
        // setAlertText({ type: 'error', text: text_alert })
        Swal.fire('', JSON.stringify(text_alert), 'error');
      }
    }
  }

  const onSaveRegister = async () => {
    let check_validate = false
    let editporfile_validate = onValidateRegister(user_data)
    for (let key in editporfile_validate) {
      check_validate = editporfile_validate[key].filter(item => item !== null).length > 0
      if (check_validate) break;
    }
    if (!check_validate) {
      try {
        let data_regis = {
          "manage": true,
          "isAwaitRegister": true,
          "application": "isee",
          "profile": {
            "name": user_data.name,
            "lastname": user_data.lastname,
            "email": user_data.email.toLowerCase(),
            "phone_number": user_data.phone_number,
            "department": user_data.department ?? null,
            "provinceId": user_data.provinceId ?? null,
            "zoneId": user_data.zoneId ?? null,
          },
          "roles": user_data.roles,
        }
        const res = await api.sendMailRegister(data_regis)
        setOpenModalRegister(false)
        handleCloseModal()
        setReload(true)
        Swal.fire('', 'บันทึกสำเร็จ', 'success');
      } catch (err) {
        Swal.fire('', err + '', 'error');
      }
    }
  }

  const onSaveDeactivate = async () => {
    try {
      // TODO: change to user edit profile
      let data_regis = {
        "manage": true,
        "user": user_data.user,
        "deactivate": user_data.deactivate,
      }
      const res = await api.deactivateUser(data_regis)
      setOpenModalDeactivate(false)
      let index = table_users.findIndex(x => user_data.user == x.user)
      console.log("<<< index", index)
      table_users[index].deactivate = user_data.deactivate
      setTableUsers([...table_users])
      setUserData({ ...DEFAULT_USER })
      Swal.fire('', 'บันทึกสำเร็จ', 'success');
    } catch (err) {
      setOpenModalDeactivate(false)
      Swal.fire('', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง', 'error');
    }
  }

  const onSendEmailAgain = async () => {
    try {
      let _data = {
        "manage": true,
        "isAwaitRegister": true,
        "isAgain": true,
        "application": "isee",
        "profile": {
          "name": user_data.name,
          "lastname": user_data.lastname,
          "email": user_data.email.toLowerCase(),
          "phone_number": user_data.phone_number,
          "department": user_data.department ?? null,
          "provinceId": user_data.provinceId ?? null,
          "zoneId": user_data.zoneId ?? null,
        },
        "roles": user_data.roles,
      }
      console.log("_data", _data);
      const res = await api.sendMailRegister(_data)
      setOpenModalSendEmail(false);
      handleCloseModal()
      // setReload(true)
      Swal.fire('', 'ส่งอีเมลสำเร็จ', 'success');
    } catch (err) {
      Swal.fire('', JSON.stringify(err), 'error');
    }
  }

  return (
    <Layout Role={['eef']}>

      <Modal
        open={openModalRegister}
        onClose={() => {
          setOpenModalRegister(false)
          handleCloseModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className='absolute top-1/2 left-1/2 w-5/6 lg:w-2/3 xl:w-1/3 rounded-xl bg-white shadow-2xl translate-x-[-50%] translate-y-[-50%] p-8 pt-4'>
          <div className='text-2xl bold'>เพิ่มผู้ใช้งาน</div>
          <div className='border border-gray-200'></div>
          <div className='mt-4'>
            <div className='grid grid-cols-6 gap-2'>
              <FormControl className="col-span-6" fullWidth margin="normal" size="small">
                <InputLabel id="roles-select-small">บทบาท</InputLabel>
                <Select
                  id="roles"
                  name="roles"
                  onChange={(e) => setUserData({ ...user_data, roles: [e.target.value] })}
                  value={user_data.roles ? user_data.roles : ''}
                  error={validate_field.roles.filter(item => item !== null).length > 0}
                  helperText={validate_field.roles.filter(item => item !== null).length > 0 ? validate_field.roles.filter(item => item !== null)[0] : ''}>
                  <MenuItem value="eef">{MAP_ROLE_TEXT['eef']}</MenuItem>
                  <MenuItem value="partner">{MAP_ROLE_TEXT['partner']}</MenuItem>
                  <MenuItem value="representative">{MAP_ROLE_TEXT['representative']}</MenuItem>
                  <MenuItem value="partner_province">{MAP_ROLE_TEXT['partner_province']}</MenuItem>
                </Select>
              </FormControl>
              {
                user_data.roles?.includes('partner') || user_data.roles?.includes('partner_province') ? 
                  <TextField
                    size="small"
                    className="col-span-6"
                    onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                    required
                    error={validate_field.department.filter(item => item !== null).length > 0}
                    helperText={validate_field.department.filter(item => item !== null).length > 0 ? validate_field.department.filter(item => item !== null)[0] : ''}
                    margin="normal"
                    id="department"
                    name="department"
                    label="หน่วยงาน"
                    inputProps={{ maxLength: 50 }}
                  /> : <></>
              }
              {
                user_data.roles?.includes('eef') &&
                <FormControl className="col-span-6" fullWidth margin="normal" size="small">
                  <InputLabel id="sumnuk-select-small">สำนัก</InputLabel>
                  <Select
                    id="department"
                    name="department"
                    onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                    value={user_data.department ? user_data.department : ''}
                    error={validate_field.department.filter(item => item !== null).length > 0}
                    helperText={validate_field.department.filter(item => item !== null).length > 0 ? validate_field.department.filter(item => item !== null)[0] : ''}>
                    {
                      SUMNUK.map(item => {
                        return <MenuItem value={item.name}>{item.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              }
              {
                user_data.roles?.includes('representative') &&
                <>
                  <FormControl className="col-span-6" fullWidth margin="normal" size="small">
                    <InputLabel id="roles-select-small">จังหวัด</InputLabel>
                    <Select
                      id="provinceId"
                      name="provinceId"
                      onChange={(e) => setUserData({ ...user_data, provinceId: e.target.value, zoneId: null })}
                      value={user_data.provinceId ? user_data.provinceId : ''}
                      error={validate_field.provinceId.filter(item => item !== null).length > 0}
                      helperText={validate_field.provinceId.filter(item => item !== null).length > 0 ? validate_field.provinceId.filter(item => item !== null)[0] : ''}>
                      {
                        list_zones.map(item => {
                          return <MenuItem value={item.province_id}>{item.province_name}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                  <FormControl className="col-span-6" fullWidth margin="normal" size="small">
                    <InputLabel id="roles-select-small">เขตพื้นที่</InputLabel>
                    <Select
                      id="zoneId"
                      name="zoneId"
                      onChange={(e) => setUserData({ ...user_data, zoneId: e.target.value })}
                      value={user_data.zoneId ? user_data.zoneId : ''}
                      error={validate_field.zoneId.filter(item => item !== null).length > 0}
                      helperText={validate_field.zoneId.filter(item => item !== null).length > 0 ? validate_field.zoneId.filter(item => item !== null)[0] : ''}>
                      {
                        user_data.provinceId ? list_zones.filter(item => item.province_id === user_data.provinceId).length > 0 ?
                          list_zones.filter(item => item.province_id === user_data.provinceId)[0].zone.map(item => {
                            return <MenuItem value={item}>{`เขต ${item}`}</MenuItem>
                          }) : <></> : <></>
                      }
                    </Select>
                  </FormControl>
                </>
              }
              {
                user_data.roles?.includes('partner_province') &&
                <FormControl className="col-span-6" fullWidth margin="normal" size="small">
                  <InputLabel id="roles-select-small">จังหวัด</InputLabel>
                  <Select
                    id="provinceId"
                    name="provinceId"
                    onChange={(e) => setUserData({ ...user_data, provinceId: e.target.value, zoneId: null })}
                    value={user_data.provinceId ? user_data.provinceId : ''}
                    error={validate_field.provinceId.filter(item => item !== null).length > 0}
                    helperText={validate_field.provinceId.filter(item => item !== null).length > 0 ? validate_field.provinceId.filter(item => item !== null)[0] : ''}>
                    {
                      list_zones.map(item => {
                        return <MenuItem value={item.province_id}>{item.province_name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              }
              <TextField
                size="small"
                className="col-span-6"
                onChange={(e) => setUserData({ ...user_data, name: e.target.value })}
                required
                error={validate_field.name.filter(item => item !== null).length > 0}
                helperText={validate_field.name.filter(item => item !== null).length > 0 ? validate_field.name.filter(item => item !== null)[0] : ''}
                margin="normal"
                id="firstname"
                name="firstname"
                label="ชื่อ"
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                size="small"
                className="col-span-6"
                onChange={(e) => setUserData({ ...user_data, lastname: e.target.value })}
                required
                error={validate_field.lastname.filter(item => item !== null).length > 0}
                helperText={validate_field.lastname.filter(item => item !== null).length > 0 ? validate_field.lastname.filter(item => item !== null)[0] : ''}
                margin="normal"
                id="lastname"
                name="lastname"
                label="นามสกุล"
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                size="small"
                className="col-span-6"
                onChange={(e) => setUserData({ ...user_data, email: e.target.value })}
                required
                error={validate_field.email.filter(item => item !== null).length > 0}
                helperText={validate_field.email.filter(item => item !== null).length > 0 ? validate_field.email.filter(item => item !== null)[0] : ''}
                margin="normal"
                id="email"
                name="email"
                label="Email"
                value={user_data.email}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className='border border-gray-200'></div>
          <div className='flex flex-row-reverse mt-4 gap-4'>
            <Button
              style={{ backgroundColor: '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => {
                setOpenModalRegister(false)
                handleCloseModal()
              }}>
              ปิด
            </Button>
            <Button
              style={{ backgroundColor: '#047857', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => onSaveRegister()}>
              บันทึก
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalChangePass}
        onClose={() => {
          setOpenModalChangePass(false)
          handleCloseModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className='absolute top-1/2 left-1/2 w-5/6 lg:w-2/3 xl:w-1/3 rounded-xl bg-white shadow-2xl translate-x-[-50%] translate-y-[-50%] p-8 pt-4'>
          <div className='text-2xl bold'>รีเซ็ตพาสเวิร์ด</div>
          <div className='border border-gray-200'></div>
          <div className='mt-4'>
            <div className='text-lg bold'>Username : <span>{user_data.user}</span></div>
            <div className='text-lg bold'>ส่งอีเมลสำหรับเปลี่ยนรหัสผ่านใหม่ของ email : &nbsp;<span>{user_data.email}</span>&nbsp; หรือไม่ ?</div>
          </div>
          <div className='border border-gray-200'></div>
          <div className='flex flex-row-reverse mt-4 gap-4'>
            <Button
              style={{ backgroundColor: '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => {
                setOpenModalChangePass(false)
                handleCloseModal()
              }}>ยกเลิก</Button>
            <Button
              style={{ backgroundColor: '#047857', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={onSaveChangePass}>ใช่</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalEditProfile}
        onClose={() => {
          setOpenModalEditProfile(false)
          handleCloseModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className='absolute top-1/2 left-1/2 w-5/6 lg:w-2/3 xl:w-1/2 rounded-xl bg-white shadow-2xl translate-x-[-50%] translate-y-[-50%] p-8 pt-4'>
          <div className='text-2xl bold'>แก้ไขข้อมูลผู้ใช้งาน</div>
          <div className='border border-gray-200'></div>
          <div className='mt-4'>
            <div className='text-lg bold'>Username : <span>{user_data.user}</span></div>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="ชื่อ"
                  name="name"
                  autoComplete="name"
                  onChange={(e) => setUserData({ ...user_data, name: e.target.value })}
                  value={user_data.name ? user_data.name : ''}
                  error={validate_field.name.filter(item => item !== null).length > 0}
                  helperText={validate_field.name.filter(item => item !== null).length > 0 ? validate_field.name.filter(item => item !== null)[0] : ''}
                />
              </div>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="lastname"
                  label="สกุล"
                  name="lastname"
                  autoComplete="lastname"
                  onChange={(e) => setUserData({ ...user_data, lastname: e.target.value })}
                  value={user_data.lastname ? user_data.lastname : ''}
                  error={validate_field.lastname.filter(item => item !== null).length > 0}
                  helperText={validate_field.lastname.filter(item => item !== null).length > 0 ? validate_field.lastname.filter(item => item !== null)[0] : ''}
                />
              </div>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="position"
                  label="ตำแหน่ง"
                  name="position"
                  autoComplete="position"
                  onChange={(e) => setUserData({ ...user_data, position: e.target.value })}
                  value={user_data.position ? user_data.position : ''}
                  error={validate_field.position.filter(item => item !== null).length > 0}
                  helperText={validate_field.position.filter(item => item !== null).length > 0 ? validate_field.position.filter(item => item !== null)[0] : ''}
                />
              </div>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  className='bg-gray-100'
                  margin="normal"
                  required
                  fullWidth
                  id="phone_number"
                  label="เบอร์โทรศัพท์"
                  name="phone_number"
                  autoComplete="phone_number"
                  value={user_data.phone_number ? user_data.phone_number : ''}
                  disabled
                />
              </div>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  className='bg-gray-100'
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={user_data.email ? user_data.email : ''}
                  disabled
                />
              </div>
              <div className='col-span-1'>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="facebook_line"
                  label="Facebook/Line"
                  name="facebook_line"
                  autoComplete="facebook_line"
                  onChange={(e) => setUserData({ ...user_data, facebook_line: e.target.value })}
                  value={user_data.facebook_line ? user_data.facebook_line : ''}
                  error={validate_field.facebook_line.filter(item => item !== null).length > 0}
                  helperText={validate_field.facebook_line.filter(item => item !== null).length > 0 ? validate_field.facebook_line.filter(item => item !== null)[0] : ''}
                />
              </div>
              {
                user_data.roles?.includes('partner') ?
                  <div className='col-span-1'>
                    <TextField
                      size="small"
                      fullWidth
                      onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                      required
                      value={user_data.department ? user_data.department : ''}
                      error={validate_field.department.filter(item => item !== null).length > 0}
                      helperText={validate_field.department.filter(item => item !== null).length > 0 ? validate_field.department.filter(item => item !== null)[0] : ''}
                      margin="normal"
                      id="department"
                      name="department"
                      label="หน่วยงาน"
                      inputProps={{ maxLength: 50 }}
                    />
                  </div> : <></>
              }
              {
                user_data.roles?.includes('eef') ?
                  <div className='col-span-1'>
                    <FormControl fullWidth margin="normal" size="small">
                      <InputLabel id="sumnuk-select-small">สำนัก</InputLabel>
                      <Select
                        id="department"
                        name="department"
                        onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                        value={user_data.department ? user_data.department : ''}
                        error={validate_field.department.filter(item => item !== null).length > 0}
                        helperText={validate_field.department.filter(item => item !== null).length > 0 ? validate_field.department.filter(item => item !== null)[0] : ''}>
                        {
                          SUMNUK.map(item => {
                            return <MenuItem value={item.name}>{item.name}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  </div> : user_data.roles?.includes('representative') ?
                    <>
                      <div className='col-span-1'>
                        <FormControl fullWidth margin="normal" size="small">
                          <InputLabel id="roles-select-small">จังหวัด</InputLabel>
                          <Select
                            id="provinceId"
                            name="provinceId"
                            required
                            onChange={(e) => setUserData({ ...user_data, provinceId: e.target.value, zoneId: null })}
                            value={user_data.provinceId ? user_data.provinceId : ''}
                            error={validate_field.provinceId.filter(item => item !== null).length > 0}
                            helperText={validate_field.provinceId.filter(item => item !== null).length > 0 ? validate_field.provinceId.filter(item => item !== null)[0] : ''}>
                            {
                              list_zones.map(item => {
                                return <MenuItem value={item.province_id}>{item.province_name}</MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-span-1'>
                        <FormControl fullWidth margin="normal" size="small">
                          <InputLabel id="roles-select-small">เขตพื้นที่</InputLabel>
                          <Select
                            id="zoneId"
                            name="zoneId"
                            required
                            onChange={(e) => setUserData({ ...user_data, zoneId: e.target.value })}
                            value={user_data.zoneId ? user_data.zoneId : ''}
                            error={validate_field.zoneId.filter(item => item !== null).length > 0}
                            helperText={validate_field.zoneId.filter(item => item !== null).length > 0 ? validate_field.zoneId.filter(item => item !== null)[0] : ''}>
                            {
                              user_data.provinceId ? list_zones.filter(item => item.province_id === user_data.provinceId).length > 0 ?
                                list_zones.filter(item => item.province_id === user_data.provinceId)[0].zone.map(item => {
                                  return <MenuItem value={item}>{`เขต ${item}`}</MenuItem>
                                }) : <></> : <></>
                            }
                          </Select>
                        </FormControl>
                      </div>
                    </>
                    : <></>
              }
              <div className='col-span-3 col-start-1'>
                <TextField
                  id="outlined-multiline-static"
                  margin="normal"
                  fullWidth
                  multiline
                  label="วัตถุประสงค์ในการนำข้อมูลไปใช้"
                  name="objective"
                  autoComplete="objective"
                  onChange={(e) => setUserData({ ...user_data, objective: e.target.value })}
                  value={user_data.objective ? user_data.objective : ''}
                  error={validate_field.objective.filter(item => item !== null).length > 0}
                  helperText={validate_field.objective.filter(item => item !== null).length > 0 ? validate_field.objective.filter(item => item !== null)[0] : ''}
                />
              </div>
            </div>
          </div>
          <div className='border border-gray-200'></div>
          <div className='flex flex-row-reverse mt-4 gap-4'>

            <Button
              style={{ backgroundColor: '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => {
                setOpenModalEditProfile(false)
                handleCloseModal()
              }}>ปิด</Button>
            <Button
              style={{ backgroundColor: '#047857', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={onSaveProfileChange}>ใช่</Button>

            {/* <Button variant="contained" color="error" onClick={() => {
              setOpenModalEditProfile(false)
              handleCloseModal()
            }}>ปิด</Button>
            <Button variant="contained" onClick={() => onSaveProfileChange()}>บันทึก</Button> */}
            {/* {
              alert_text.type !== '' ?
                <Alert
                  // variant="outlined" 
                  severity={alert_text.type}>
                  {alert_text.text}
                </Alert>
                : <></>
            } */}
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalDeactivate}
        onClose={() => {
          setOpenModalDeactivate(false)
          handleCloseModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className='absolute top-1/2 left-1/2 w-5/6 lg:w-2/3 xl:w-1/3 rounded-xl bg-white shadow-2xl translate-x-[-50%] translate-y-[-50%] p-8 pt-4'>
          <div className='text-2xl bold'>{user_data.deactivate ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน'}</div>
          <div className='border border-gray-200'></div>
          <div className='mt-4'>
            <div className='text-lg bold'>คุณต้องการ{user_data.deactivate ? 'ปิด' : 'เปิด'}การใช้งานของ User : &nbsp;<span>{user_data.user}</span>&nbsp; ใช่หรือไม่ ?</div>
          </div>
          <div className='border border-gray-200'></div>
          <div className='flex flex-row-reverse mt-4 gap-4'>
            <Button
              style={{ backgroundColor: '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => {
                setOpenModalDeactivate(false)
                handleCloseModal()
              }}>ยกเลิก</Button>
            <Button
              style={{ backgroundColor: '#047857', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => onSaveDeactivate()}>ใช่</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalSendEmail}
        onClose={() => {
          setOpenModalSendEmail(false)
          handleCloseModal()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className='absolute top-1/2 left-1/2 w-5/6 lg:w-2/3 xl:w-1/3 rounded-xl bg-white shadow-2xl translate-x-[-50%] translate-y-[-50%] p-8 pt-4'>
          <div className='text-2xl bold'>{'ส่งอีเมลสำหรับสมัครใช้งานใหม่'}</div>
          <div className='border border-gray-200'></div>
          <div className='mt-4'>
            <div className='text-lg bold'>ส่งอีเมลสำหรับสมัครใช้งานใหม่ของ email : &nbsp;<span>{user_data.email}</span>&nbsp; อีกครั้งใช่หรือไม่ ?</div>
          </div>
          <div className='border border-gray-200'></div>
          <div className='flex flex-row-reverse mt-4 gap-4'>
            <Button
              style={{ backgroundColor: '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={() => {
                setOpenModalSendEmail(false)
                handleCloseModal()
              }}>ยกเลิก</Button>
            <Button
              style={{ backgroundColor: '#047857', borderRadius: 4, marginLeft: 4, color: '#fff', width: 100 }}
              onClick={onSendEmailAgain}>ใช่</Button>
          </div>
        </div>
      </Modal>

      <div className='relative overflow-hidden'>
        <div className='w-[100vw] '>
          <div className='grid grid-cols-10 gap-4 p-8 mt-[80px]'>
            <div className='col-span-10 lg:col-span-2'>
              <div className='w-full lg:h-[830px] bg-white rounded-md drop-shadow-xl p-8'>
                <h1 className='text-2xl'>ค้นหาผู้ใช้งาน</h1>
                <div className='grid grid-cols-10 gap-2'>
                  <div className='col-span-5 lg:col-span-10'>
                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="filter-name"
                      label="Username / ชื่อผู้ใช้"
                      name="filter-name"
                      value={search_filter.name}
                      onChange={(e) => setSearchFilter({ ...search_filter, name: e.target.value })}
                    />
                  </div>
                  <div className='col-span-5 lg:col-span-10'>
                    <FormControl fullWidth margin="normal" size="small">
                      <InputLabel id="filter-roles-select-small">บทบาท</InputLabel>
                      <Select
                        id="filter-roles"
                        label="บทบาท"
                        name="filter-roles"
                        value={search_filter.roles}
                        onChange={(e) => setSearchFilter({ ...search_filter, roles: e.target.value })}>
                        <MenuItem value="eef">{MAP_ROLE_TEXT['eef']}</MenuItem>
                        <MenuItem value="partner">{MAP_ROLE_TEXT['partner']}</MenuItem>
                        <MenuItem value="representative">{MAP_ROLE_TEXT['representative']}</MenuItem>
                        <MenuItem value="partner_province">{MAP_ROLE_TEXT['partner_province']}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='col-span-10 lg:col-span-10'>
                    <div className='flex flex-row-reverse lg:flex-col-reverse mt-4 gap-4'>
                      <Button
                        size='large'
                        style={{ backgroundColor: '#0369a1', color: 'white', borderRadius: 4 }}
                        className="lg:w-full"
                        onClick={() => {
                          setSearchFilter({ name: '', roles: '' })
                          setTableUsers(users)
                        }}>
                        <Space>
                          <RedoOutlined />
                          ล้างค่าการค้นหา
                        </Space>
                      </Button>
                      <Button
                        size='large'
                        style={{ backgroundColor: '#15803d', color: 'white', borderRadius: 4 }}
                        onClick={() => {
                          let data_filter = []
                          if (search_filter.name) {
                            data_filter = users.filter(item => {
                              return (
                                item.user?.includes(search_filter.name) ||
                                item.profile?.name?.includes(search_filter.name) ||
                                item.profile?.lastname?.includes(search_filter.name)
                              )
                            })
                          } else {
                            data_filter = users
                          }

                          if (search_filter.roles) {
                            data_filter = data_filter.filter(item => item.roles.includes(search_filter.roles))
                          }

                          setTableUsers(data_filter)
                        }}>
                        <Space>
                          <SearchOutlined />
                          ค้นหา
                        </Space>

                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-span-10 lg:col-span-8'>
              <div className='w-full h-[830px] bg-white rounded-md drop-shadow-xl p-8'>
                <div className='flex flex-row'>
                  <div className='mr-auto'>
                    <h1 className='text-2xl'>รายชื่อผู้ใช้งาน</h1>
                  </div>
                  <div className='ml-auto my-auto'>
                    <Button
                      size='large'
                      style={{ backgroundColor: '#15803d', color: 'white', borderRadius: 4 }}
                      className="lg:w-full"
                      onClick={register}>
                      <Space>
                        <PlusOutlined />
                        เพิ่มผู้ใช้งาน
                      </Space>
                    </Button>
                  </div>
                </div>
                <div>
                  <Table
                    size="small"
                    bordered
                    style={{ width: '100%', height: '100%' }}
                    scroll={{ x: 1200, y: 700 }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                    columns={[
                      {
                        title: <div className='text-lg'>ผู้ใช้งาน</div>,
                        dataIndex: 'user',
                        key: 'user',
                        align: 'center',
                        render(text, record) {
                          let item = <div className='text-left'>{text}</div>
                          if (record.is_wait_register_email) item = <Tag color="blue">รอการสร้างผู้ใช้งาน</Tag>
                          return ({
                            children: item
                          });
                        },
                        sorter: (a, b) => a.user.localeCompare(b.user),
                      },
                      {
                        title: <div className='text-lg'>ชื่อ-สกุลผู้ใช้</div>,
                        dataIndex: 'profile',
                        key: 'profile',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div className='text-left'>{`${text.name} ${text.lastname}`}</div>
                          };
                        },
                        sorter: (a, b) => a.profile?.name?.localeCompare(b.profile?.name),
                      },
                      {
                        title: <div className='text-lg'>หน่วยงาน</div>,
                        dataIndex: 'profile',
                        key: 'profile',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div className='text-left'>{`${text.department}`}</div>
                          };
                        },
                        sorter: (a, b) => a.profile?.department?.localeCompare(b.profile?.department),
                      },
                      {
                        title: <div className='text-lg'>บทบาท</div>,
                        dataIndex: 'roles',
                        key: 'roles',
                        align: 'center',
                        render(text, record) {
                          // รอการสร้างรหัสผ่าน
                          return {
                            children: <div className='ml-4 my-auto'>
                              <ul class="list-disc text-left">
                                {
                                  text.map(element => {
                                    return <li>{MAP_ROLE_TEXT[element]}</li>
                                  })
                                }
                              </ul>
                            </div>
                          };
                        },
                        // sorter: (a, b) => a.roles?.localeCompare(b.roles),
                      },
                      {
                        title: <div className='text-lg'>สถานะ</div>,
                        dataIndex: 'roles',
                        key: 'roles',
                        width: 130,
                        align: 'center',
                        render(text, record) {
                          let item = null
                          if (record.deactivate) item = <Tag color="red">ถูกระงับการใช้งาน</Tag>
                          else if (record.is_wait_register_email) item = <Tag color="blue">รอการสร้างผู้ใช้งาน</Tag>
                          else {
                            item = <Tag color="green"> ปกติ</Tag>
                          }
                          return {
                            children: <div className='my-auto'>
                              {item}
                            </div>
                          };
                        },
                        // sorter: (a, b) => a.roles?.localeCompare(b.roles),
                      },
                      {
                        title: <div className='text-lg'>จัดการ</div>,
                        dataIndex: 'user',
                        key: 'user',
                        width: 430,
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div className='flex flex-row'>

                              {
                                !(record.roles.includes('admin') || record.deactivate || record.is_wait_register_email) &&
                                <Button
                                  style={{ backgroundColor: '#0369a1', borderRadius: 4, marginLeft: 4, color: '#fff' }}
                                  onClick={() => changePass(record)}
                                >
                                  <Space>
                                    <MailOutlined />
                                    รีเซ็ตพาสเวิร์ด
                                  </Space>

                                </Button>
                              }

                              {
                                !(record.roles.includes('admin') || record.deactivate || record.is_wait_register_email) &&
                                <Button
                                  style={{ backgroundColor: '#a16207', borderRadius: 4, marginLeft: 4, color: '#fff' }}
                                  onClick={() => editProfile(record)}
                                >
                                  <Space>
                                    <FormOutlined />
                                    แก้ไขข้อมูล
                                  </Space>
                                </Button>
                              }

                              {
                                !(record.roles.includes('admin') || record.is_wait_register_email) &&
                                <Button
                                  style={{ backgroundColor: record.deactivate ? '#047857' : '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff' }}
                                  onClick={() => deactivateUser(record, !record.deactivate)}
                                >
                                  <Space>
                                    {record.deactivate ? <RedoOutlined /> : <CloseCircleOutlined />}
                                    {record.deactivate ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                                  </Space>
                                </Button>
                              }

                              {
                                record.is_wait_register_email &&
                                <Button
                                  style={{ backgroundColor: record.deactivate ? '#047857' : '#991b1b', borderRadius: 4, marginLeft: 4, color: '#fff' }}
                                  onClick={() => sendEmailAgain(record)}
                                >
                                  <Space>
                                    <MailOutlined />
                                    ส่ง Email อีกครั้ง
                                  </Space>
                                </Button>
                              }
                            </div>
                          };
                        },
                      }

                    ]}
                    dataSource={table_users}
                  >
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </Layout >
  )
}

export default PageAdmin



